<template>
    <div class="mt-3 px-xl-5">
        <b-row class="mt-4 text-uppercase">
            <h6 class="page-title pb-2">{{ $t("procedure-list.title") }}</h6>
        </b-row>
        <b-row class="justify-content-end">
            <b-col xl="3" md="6">
                <b-input-group class="my-3">
                    <b-form-input
                        placeholder="Buscar"
                        class="rounded-0"
                        size="sm"
                    ></b-form-input>
                    <b-input-group-append>
                        <b-button class="main-btn" size="sm">
                            <b-icon-search></b-icon-search>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
        </b-row>
        <div class="mb-5">
            <b-list-group>
                <b-list-group-item
                    v-for="item in options"
                    :key="item.value"
                    class="d-flex justify-content-between align-items-center"
                    @click="getDetails(item.routeName)"
                >
                    <span class="form-title">{{ item.text }}</span>
                    <b-icon-file-earmark-plus scale="1.6"></b-icon-file-earmark-plus>
                </b-list-group-item>
            </b-list-group>
        </div>
    </div>
</template>

<script>
export default {
    name: "procedures-list",
    data() {
        return {
            options: [
                {
                    text: "Solicitar beneficiario final",
                    routeName: "final-beneficiary-request-start",
                },
            ],
        };
    },
    methods: {
        getDetails(name) {
            this.$router.push({
                name: name,
            });
        },
    },
};
</script>
